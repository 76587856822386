<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-card class="card-style mb-4" elevation="0" rounded="lg">
        <v-row>
          <v-col cols="6">
            <v-img contain src="../assets/images/apparel.svg" height="60"></v-img>
          </v-col>
          <v-col cols="6" class="mx-auto my-auto">
            <h2 class="fg-color">APPAREL</h2>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-card class="card-style mb-4" elevation="0" rounded="lg">
        <v-row>
          <v-col cols="6">
            <v-img contain src="../assets/images/swags.svg" height="60"></v-img>
          </v-col>
          <v-col cols="6" class="mx-auto my-auto">
            <h2 class="fg-color">SWAGS</h2>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-card elevation="0" class="card-style mb-4" rounded="lg">
        <v-row>
          <v-col cols="6">
            <v-img contain src="../assets/images/bag.svg" height="60"></v-img>
          </v-col>
          <v-col cols="6" class="mx-auto my-auto">
            <h2 class="fg-color">BAG</h2>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-style {
  background-color: rgba(51, 148, 0, 0.1);
}
h2 {
  font-family: "Poppins", sans-serif;
}
</style>
