<template>
  <div>
    <hero-section />
    <branding-cards />
    <our-products />
    <product-in-tab />
    <our-promise />
  </div>
</template>

<script>
import BrandingCards from "../components/BrandingCards.vue";
import HeroSection from "../components/HeroSection.vue";
import OurProducts from "../components/OurProducts.vue";
import OurPromise from "../components/OurPromise.vue";
import ProductInTab from "../components/ProductInTab.vue";
export default {
  components: { HeroSection, BrandingCards, OurProducts, OurPromise, ProductInTab },
};
</script>

<style lang="scss" scoped></style>
