<template>
  <div class="custom-container">
    <v-card rounded="lg" elevation="0" v-if="isEnabled">
      <v-tabs color="green darken-1" centered>
        <v-tab class="primary-font" v-for="category in filterCategories" :key="category.id">
          <img v-show="category.url" :src="category.url" height="30" class="mr-2" />
          {{ category.name }}
        </v-tab>

        <v-tab-item v-for="category in filterCategories" :key="category.name">
          <v-container fluid class="container-height">
            <v-row v-if="category.name === 'ALL'">
              <v-col v-for="product in products" :key="product.url" cols="12" md="4">
                <v-img :src="product.url" contain height="300">
                  <template v-slot:placeholder>
                    <v-sheet color="grey lighten-4">
                      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                v-for="product in products.filter((item) => item.category.name === category.name)"
                :key="product.url"
                cols="12"
                md="4"
              >
                <v-img :src="product.url" contain height="300">
                  <template v-slot:placeholder>
                    <v-sheet color="grey lighten-4">
                      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <product-cards v-else />
  </div>
</template>

<script>
import { cloudFireStore } from "../utils/firebase";
import ProductCards from "./ProductCards.vue";
export default {
  components: { ProductCards },
  data: () => ({
    categories: [],
    products: [],
    switches: [],
  }),
  computed: {
    filterCategories() {
      if (this.categories.length > 0) {
        this.categories.unshift({ id: "all", name: "ALL" });
        return this.categories;
      }
    },
    isEnabled() {
      if (this.switches.length > 0) {
        return this.switches[0].isEnabled;
      } else {
        return true;
      }
    },
  },
  firestore: {
    categories: cloudFireStore.collection("Categories"),
    products: cloudFireStore.collection("Products"),
    switches: cloudFireStore.collection("Switches"),
  },
};
</script>

<style lang="scss" scoped>
.container-height {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
