<template>
  <div class="custom-container">
    <v-row>
      <v-col cols="12">
        <div class="overline primary-font">samples are available on request</div>
        <div class="heading primary-font">Our Products</div>
        <div class="body1 primary-font">
          Our product includes various kind of <strong>Apparel</strong> , <strong>SWAGS</strong>, <strong>Bags</strong>.
          All of our products are manufactured in Bangladesh, a country known for supplying the highest quality products
          and raw materials. We take pride in our value proposition – Delivering a unique combination of competitive
          pricing and unparalleled client experience. We hope you will join us in our mission to make our planet a
          better place for all, by incorporating this golden fiber into your everyday life.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.body1 {
  line-height: 212.5%;
}
</style>
