<template>
  <div class="custom-container">
    <v-row>
      <v-col cols="12" md="6" lg="8">
        <div class="heading primary-font">
          Our Promise
        </div>
        <div class="body1 primary-font">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing odio vel viverra cursus et tellus diam
          viverra amet. Odio quis purus in nec scelerisque. Donec sed est risus scelerisque molestie enim. Id morbi
          ultrices enim nulla amet, amet, maecenas mattis enim. Nunc viverra a amet in et luctus.
          <br /><br />
          Contact us to design your free custom samples.
        </div>
        <v-btn depressed class="bg-color white--text px-8 py-6 mt-12 primary-font custom-radius" @click="openModal"
          >Order Now</v-btn
        >
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-img contain src="../assets/images/promise.svg" height="400"></v-img>
      </v-col>
    </v-row>
    <contact-modal ref="modal_form" />
  </div>
</template>

<script>
import ContactModal from "./ContactModal.vue";
export default {
  components: { ContactModal },
  methods: {
    async openModal() {
      await this.$refs.modal_form.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.body1 {
  line-height: 212.5%;
}
</style>
